import { styled } from '@mui/system'

const Wrapper = styled('footer')`
	padding-bottom: calc(var(--footerActionBarHeight, 0px) + var(--stickyButtonsHeight, 0px));
	${({ theme }) => theme.breakpoints.up('md')} {
		border-top: 2px solid ${({ theme }) => theme.palette.footer.border};
	}
`

export { Wrapper }
