import { styled } from '@mui/system'

const MiddleTopWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleTop};
	display: flex;
	flex-direction: column;
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		padding: 45px 40px 20px;
	}
	${({ theme }) => theme.breakpoints.up('lg')} {
		padding: 45px 176px 20px;
	}
	& .BHFooterSocialLinks {
		width: 100%;
		flex-grow: 1;
		order: 1;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 22px 0 26px;
		${({ theme }) => theme.breakpoints.up('md')} {
			display: none;
		}
		& .BHSocialWrapper {
			& a {
				margin: 0;
				padding: 9px;
				color: var(--color-primary);
			}
		}
	}
	& .BHFooterMenus {
		border-top: 1px solid ${({ theme }) => theme.palette.footer.border};
		padding: 0;
		${({ theme }) => theme.breakpoints.up('md')} {
			border-top: none;
		}
		& .BhFooterMenuTitle {
			color: ${({ theme }) => theme.palette.footer.color};
		}
		& .MuiPaper-root {
			border-top: none;
			border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
			${({ theme }) => theme.breakpoints.up('md')} {
				border-bottom: none;
			}
			& .MuiAccordionSummary-content {
				color: ${({ theme }) => theme.palette.footer.color};
			}
		}
	}
	& .BHNewsletter {
		padding: 70px 8px 0;
		${({ theme }) => theme.breakpoints.up('xsm')} {
			padding: 70px 8px 0;
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			padding: 0;
		}
	}
`

const NewsletterWrapper = styled('div')`
	max-width: 100%;
	order: 1;
	${({ theme }) => theme.breakpoints.up('md')} {
		order: 2;
		width: 305px;
	}
`

export { MiddleTopWrapper, NewsletterWrapper }
