import { cx } from '@emotion/css'
import { FormattedMessage } from 'react-intl'
import MapPinIcon from '../../../atoms/icons/map-pin-icon'
import StorelocatorLink from '../storelocator-link'
import { ChangeCountryLanguageStyled, MiddleBottomWrapper } from './middle-bottom-area.styled'

const MiddleBottomArea = (className) => {
	return (
		<MiddleBottomWrapper className={cx('MiddleBottomArea-root', className)}>
			<ChangeCountryLanguageStyled
				className="change-country-language-trigger-footer"
				showFlag={false}
				showCurrencySymbol={false}
			/>

			<StorelocatorLink
				className="footer-storelocator-link d-none-sm"
				buttonProps={{
					StartIcon: MapPinIcon,
					variant: 'ghost',
					iconProps: { fontSize: 'middle' },
					label: <FormattedMessage id={'footer_store_locator_link_label'} />,
				}}
			/>
		</MiddleBottomWrapper>
	)
}

export default MiddleBottomArea
