import { styled } from '@mui/system'

const BottomAreaWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.footer.bgBottom};
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 8px 8px;
	a {
		color: ${({ theme }) => theme.palette.footer.color};
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		border-top: 1px solid ${({ theme }) => theme.palette.footer.border};
		padding: 4px 40px;
		justify-content: flex-start;
	}
	${({ theme }) => theme.breakpoints.up('lg')} {
		padding: 4px 176px;
	}
`

export { BottomAreaWrapper }
